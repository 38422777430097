import Vue from "vue";
import axios from "axios";
import store from "../store";

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Authorization: "Bearer " + store.getters["token/GET_ACCESS_TOKEN"],
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosIns.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      if (originalRequest.url === `${process.env.VUE_APP_BASE_URL}auth/refresh-token`) {
        store.commit("token/LOGOUT");
        window.location = "/";
        return Promise.reject(error);
      }

      if (localStorage.getItem('refreshToken') && !originalRequest._retry) {
        originalRequest._retry = true;

        return axios.post(`${process.env.VUE_APP_BASE_URL}auth/refresh-token`, {}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('refreshToken'),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).then(res => {
          if (res.status === 201) {
            store.commit('token/UPDATE_ACCESS_TOKEN', res.data.data.accessToken)

            axiosIns.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');

            return axios(originalRequest);
          }
        });
      }
    } else {
      // This is important, so that unhandled cases make axios throw errors
      return Promise.reject(error);
    }
  }
);

Vue.prototype.$http = axiosIns;
Vue.prototype.$axios = axiosIns;

export default axiosIns;
