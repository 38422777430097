export default [
  {
    path: "/student-attendance",
    name: "student-attendance",
    meta: {
      pageTitle: "Absensi",
      permission: {
        name: [
          "read/student-attendance",
          "edit/student-attendance",
          "destroy/student-attendance",
          "import/student-attendance",
          "export/student-attendance",
        ],
      },
      action: "read",
      actions: {},
    },
    component: () => import("@/views/attendance/Index.vue"),
  },
  {
    path: "/student-attendance/:block/show/:year",
    name: "student-attendance",
    meta: {
      pageTitle: "Absensi",
      breadcrumb: [
        {
          text: "Absensi",
          to: "/student-attendance",
          active: false,
        },
      ],
      permission: {
        name: [
          "read/student-attendance",
          "edit/student-attendance",
          "destroy/student-attendance",
          "import/student-attendance",
          "export/student-attendance",
        ],
      },
      action: "read",
      actions: {},
    },
    component: () => import("@/views/attendance/Show.vue"),
  },
  {
    path: "/student-attendance/:id/edit",
    name: "student-attendance",
    meta: {
      pageTitle: "Detail Mahasiswa",
      permission: {
        name: ["edit/student"],
      },
      action: "edit",
    },
    component: () => import("@/views/attendance/Form.vue"),
  },
];
