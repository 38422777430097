export default [
  {
    path: "/feedback-block-admin",
    name: "feedback-block-admin",
    meta: {
      pageTitle: "Kuesioner Blok",
      breadcrumb: [
        {
          text: "Kuesioner Blok",
          active: false,
        },
      ],
      // permission: {
      //   name: ["read/feedback-student", "show/feedback-student"],
      // },
      action: "read",
      actions: {},
    },
    component: () => import(`@/views/feedback-admin-block/Index.vue`),
  },
  {
    path: "/feedback-session-admin",
    name: "feedback-session-admin",
    meta: {
      pageTitle: "Kuesioner Sesi",
      breadcrumb: [
        {
          text: "Kuesioner Sesi",
          active: false,
        },
      ],
      // permission: {
      //   name: ["read/feedback-student", "show/feedback-student"],
      // },
      action: "read",
      actions: {},
    },
    component: () => import(`@/views/feedback-admin-session/Index.vue`),
  },
  {
    path: "/feedback-session-admin/create",
    name: "feedback-session-admin",
    meta: {
      pageTitle: "Kuesioner",
      breadcrumb: [
        {
          text: "Kuesioner",
          to: "/feedback-session-admin",
          active: false,
        },
      ],
      action: "store",
    },
    component: () => import(`@/views/feedback-admin-session/Form.vue`),
  },
  {
    path: "/feedback-session-admin/:id/show",
    name: "feedback-session-admin",
    meta: {
      pageTitle: "Kuesioner",
      breadcrumb: [
        {
          text: "Kuesioner",
          to: "/feedback-session-admin",
          active: false,
        },
      ],
      action: "store",
    },
    component: () => import(`@/views/feedback-admin-session/Show.vue`),
  },
];
