export default {
  namespaced: true,
  state: {
    quesionner: null,
  },
  getters: {
    GET_QUESIONNER: (state) => {
      return state.quesionner;
    },
  },
  mutations: {
    UPDATE_QUESIONNER(state, val) {
      state.quesionner = val;
    },
  },
  actions: {},
};
