export default [
  {
    path: "/transcript",
    name: "transcript",
    meta: {
      pageTitle: "Transkrip Nilai",
      permission: {
        name: [
          "read/transcript",
          "show/transcript",
          "edit/transcript",
          "destroy/transcript",
          "export/transcript",
          "import/transcript",
        ],
      },
      action: "read",
      actions: {
        show: true,
        edit: true,
        delete: false,
      },
    },
    component: () => import(`@/views/transcript/Index.vue`),
  },
];
