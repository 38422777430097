export default [
    {
      path: "/lecturer",
      name: "lecturer",
      meta: {
        pageTitle: "Dosen",
        permission: {
          name: [
            "read/lecturer",
            "show/lecturer",
            "edit/lecturer",
            "destroy/lecturer",
            "import/lecturer",
          ],
        },
        action: "read",
        actions: {
          show: true,
          edit: true,
          delete: false,
        },
      },
      component: () => import("@/views/lecturer/Index.vue"),
    },
    {
      path: "/lecturer/:id/show",
      name: "lecturer",
      meta: {
        pageTitle: "Detail Dosen",
        breadcrumb: [
          {
            text: "Dosen",
            to: "/lecturer",
            active: false,
          },
        ],
        permission: {
          name: ["show/lecturer"],
        },
        action: "show",
      },
      component: () => import("@/views/lecturer/Form.vue"),
    },
    {
      path: "/lecturer/:id/edit",
      name: "lecturer",
      meta: {
        pageTitle: "Edit Dosen",
        breadcrumb: [
          {
            text: "Dosen",
            to: "/lecturer",
            active: false,
          },
        ],
        permission: {
          name: ["edit/lecturer"],
        },
        action: "edit",
      },
      component: () => import("@/views/lecturer/Form.vue"),
    },
  ];
  