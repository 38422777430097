export default [
  {
    path: "/suggestion-critique",
    name: "suggestion-critique",
    meta: {
      pageTitle: "Kritik & Saran",
      permission: {
        name: [
          "read/suggestion-critique","create/suggestion-critique", "edit/suggestion-critique", "show/suggestion-critque"
        ]
      },
      action: "read",
      actions: {
        show: true,
        edit: true,
        delete: false,
      },
    },
    component: () => import("@/views/suggestion-critique/Index.vue"),
  },
  {
    path: "/dashboard/suggestion-critique",
    name: "dashboard-suggestion-critique",
    meta: {
      pageTitle: "Kritik & Saran",
      permission: {
        name: [
          "read/dashboard-suggestion-critique", "show/dashboard-suggestion-critique"
        ]
      },
      action: "read",
      actions: {
        show: true,
        edit: true,
        delete: false,
      },
    },
    component: () => import("@/views/dashboard-suggestion-critique/Index.vue"),
  },
];
