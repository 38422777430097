export default {
    namespaced: true,
    state: {
        lecturer: {
            name: null,
            nip: null,
            block: null,
            course: null
        },
    },
    getters: {
        GET_USER: (state) => {
            return state.lecturer;
        },
    },
    mutations: {
        UPDATE_USER(state, val) {
            state.lecturer = val;
        },
        REMOVE_USER(state) {
            state.lecturer = {
                name: null,
                nip: null,
                block: null,
                course: null
            }
        }
    },
    actions: {},
};
