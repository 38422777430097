export default {
  namespaced: true,
  state: {
    accessToken: null,
    refreshToken: null,
    account: null,
    menu: null,
    tree: null
  },
  getters: {
    GET_ACCESS_TOKEN: (state) => {
      return state.accessToken;
    },
    GET_REFRESH_TOKEN: (state) => {
      return state.refreshToken;
    },
  },
  mutations: {
    UPDATE_ACCESS_TOKEN(state, val) {
      state.accessToken = val;
      localStorage.setItem('accessToken', val);
    },
    UPDATE_REFRESH_TOKEN(state, val) {
      state.refreshToken = val;
      localStorage.setItem('refreshToken', val);
    },
    ACCOUNT(state, val){
      state.account = JSON.stringify(val);
      localStorage.setItem('account', JSON.stringify(val));
    },
    MENU(state, val){
      state.menu = JSON.stringify(val);
      localStorage.setItem('menu', JSON.stringify(val));
    },
    TREE(state, val){
      state.tree = JSON.stringify(val);
      localStorage.setItem('tree', JSON.stringify(val))
    },
    LOGOUT(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.account = null;
      state.menu = null;
      state.tree = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('account');
      localStorage.removeItem('menu');
      localStorage.removeItem('tree');
    },
  },
  actions: {},
};
