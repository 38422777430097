

export default [
    {
        path: "/student-summaries",
        name: "student-summaries",
        meta: {
            pageTitle: "Dashboard Mahasiswa",
            breadcrumb: [
                {
                    text: "Dashboard Mahasiswa",
                    to: "/student-summaries",
                    active: false,
                },
            ],
            permission: {
                name: ["edit/student-grade", "import/student-grade"],
            },
            action: "show",
            actions: {
                import: true,
                delete: true,
            },
        },
        component: () => import("@/views/student-summaries/Index.vue"),
    },
];
