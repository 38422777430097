import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store'

Vue.use(VueRouter);

import student from "./routes/student";
import studentGrades from "./routes/student-grades";
import attendance from "./routes/attendance";
import studyPlan from "./routes/study-plan";
import feedback from "./routes/feedback";
import feedbackAdmin from "./routes/feedback-admin";
import transcript from "./routes/transcript";
import studentSummaries from "./routes/student-summaries"
import lecturerSummaries from "./routes/lecturer-summaries";
import lecturer from "./routes/lecturer";
import feedbackLecturer from "./routes/feedback-lecturer";
import event from "./routes/event";
import critique from "./routes/critique";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "login",
      component: () => import("@/views/Index.vue"),
      meta: {
        layout: "full",
        isGuest: true,
      },
    },
    {
      path: "/landing-page",
      name: "landing-page",
      component: () => import("@/views/landing-page/Index.vue"),
      meta: {
        layout: "full",
        isGuest: true,
      },
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/home/Index.vue"),
      meta: {
        pageTitle: "Beranda",
        breadcrumb: [
          {
            text: "Beranda",
            active: true,
          },
        ],
      },
    },
    ...student,
    ...studentGrades,
    ...attendance,
    ...studyPlan,
    ...feedback,
    ...transcript,
    ...studentSummaries,
    ...lecturerSummaries,
    ...lecturer,
    ...feedbackLecturer,
    ...event,
    ...critique,
    ...feedbackAdmin,
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.isGuest && !localStorage.getItem("accessToken")) {
    next();
  } else if (!localStorage.getItem("accessToken")) {
    next({ name: "login" });
  } else if (to.meta.permission) {
    const menuPermissions = to.meta.permission.name;
    const menus = JSON.parse(localStorage.getItem("menu"));
    const menu = menus.find((menu) => menu.route === to.name);

    if (menu) {
      const permissions = menu.permissions;
      let actions = {};
      for (const data of permissions) {

        if (menuPermissions.includes(data.name)) {
          const permission = data.name.replace(`/${to.name}`, "");
          actions[permission] = true;
        }
      }
      store.commit('app/UPDATE_ACTIONS', actions);
      next();
    } else {
      next({ name: from.name });
    }
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
