export default [
  {
    path: "/student",
    name: "student",
    meta: {
      pageTitle: "Mahasiswa",
      permission: {
        name: [
          "read/student",
          "show/student",
          "edit/student",
          "destroy/student",
          "export/student",
          "import/student",
        ],
      },
      action: "read",
      actions: {
        show: true,
        edit: true,
        delete: false,
      },
    },
    component: () => import("@/views/student/Index.vue"),
  },
  {
    path: "/student/:id/show",
    name: "student",
    meta: {
      pageTitle: "Detail Mahasiswa",
      breadcrumb: [
        {
          text: "Mahasiswa",
          to: "/student",
          active: false,
        },
      ],
      permission: {
        name: ["show/student"],
      },
      action: "show",
    },
    component: () => import("@/views/student/Form.vue"),
  },
  {
    path: "/student/:id/edit",
    name: "student",
    meta: {
      pageTitle: "Edit Mahasiswa",
      breadcrumb: [
        {
          text: "Mahasiswa",
          to: "/student",
          active: false,
        },
      ],
      permission: {
        name: ["edit/student"],
      },
      action: "edit",
    },
    component: () => import("@/views/student/Form.vue"),
  },
  {
    path: "/student/add",
    name: "student",
    meta: {
      pageTitle: "Tambah Mahasiswa",
      breadcrumb: [
        {
          text: "Mahasiswa",
          to: "/student",
          active: false,
        },
      ],
      permission: {
        name: ["add/student"],
      },
      action: "add",
    },
    component: () => import("@/views/student/Form.vue"),
  },
];
