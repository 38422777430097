let role = null;

if (localStorage.getItem("account")) {
  const account = JSON.parse(localStorage.getItem("account"));
  if (account.role.name === "student") {
    role = account.role.name;
  }
}
const folder = role ? "feedback-student" : "feedback";

export default [
  {
    path: "/feedback",
    name: "feedback",
    meta: {
      pageTitle: "Kuesioner Blok",
      breadcrumb: [
        {
          text: "Kuesioner Blok",
          active: false,
        },
      ],
      // permission: {
      //   name: ["read/feedback-student", "show/feedback-student"],
      // },
      action: "read",
      actions: {},
    },
    component: () => import(`@/views/feedback/Index.vue`),
  },
  {
    path: "/feedback/block",
    name: "feedback-block",
    meta: {
      pageTitle: "Kuesioner Blok",
      breadcrumb: [
        {
          text: "Kuesioner Blok",
          active: false,
        },
      ],
      // permission: {
      //   name: ["read/feedback-student", "show/feedback-student"],
      // },
      action: "read",
      actions: {},
    },
    component: () => import(`@/views/${folder}-block/Index.vue`),
  },
  {
    path: "/feedback/block/answer",
    name: "feedback",
    meta: {
      pageTitle: "Kuesioner Blok",
      breadcrumb: [
        {
          text: "Kuesioner Blok",
          to: "feedback-block",
          active: false,
        },
      ],
      action: "store",
    },
    component: () => import(`@/views/${folder}-block/Form.vue`),
  },
  {
    path: "/feedback/session",
    name: "feedback-session",
    meta: {
      pageTitle: "Kuesioner Sesi",
      breadcrumb: [
        {
          text: "Kuesioner Sesi",
          active: false,
        },
      ],
      // permission: {
      //   name: ["read/feedback-student", "show/feedback-student"],
      // },
      action: "read",
      actions: {},
    },
    component: () => import(`@/views/${folder}-session/Index.vue`),
  },
  {
    path: "/feedback/session/answer/:code",
    name: "feedback",
    meta: {
      pageTitle: "Kuesioner Sesi",
      breadcrumb: [
        {
          text: "Kuesioner Sesi",
          active: false,
        },
      ],
      // permission: {
      //   name: ["read/feedback-student", "show/feedback-student"],
      // },
      action: "read",
      actions: {},
    },
    component: () => import(`@/views/${folder}-session/Form.vue`),
  },
];
