import { ValidationProvider, extend } from "vee-validate";
import { required, min_value } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} harus diisi",
});
extend("minValue", {
  ...min_value,
  message: "{_field_} tidak boleh 0",
});

export default ValidationProvider;
