export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    GET_USER: (state) => {
      return state.user;
    },
  },
  mutations: {
    UPDATE_USER(state, val) {
      state.user = val;
    },
  },
  actions: {},
};
