let role = 'head-lecturer';

if(localStorage.getItem('account')){
  const account = JSON.parse(localStorage.getItem('account'));
  role = (account.entity);
  if(account.role.name == "head_lecturer" || account.role.name == "administrator" ){
    role = 'head-lecturer';
  }
}

export default [
  {
    path: "/student-grade",
    name: "student-grade",
    meta: {
      pageTitle: "Nilai Mahasiswa",
      permission: {
        name: [
          "read/student-grade",
          "show/student-grade",
          "edit/student-grade",
          "destroy/student-grade",
          "export/student-grade",
          "import/student-grade",
          "print/student-grade",
        ],
      },
      action: "read",
      actions: {
        show: true,
        edit: true,
        delete: false,
      },
    },
    component: () => import(`@/views/student-grades-${role}/Index.vue`),
  },
  {
    path: "/student-grade/:block/show/:year",
    name: "student-grade",
    meta: {
      pageTitle: "Detail Nilai Mahasiswa",
      breadcrumb: [
        {
          text: "Nilai Mahasiswa",
          to: "/student-grade",
          active: false,
        },
      ],
      permission: {
        name: ["edit/student-grade","import/student-grade"],
      },
      action: "show",
      actions: {
        import: true,
        delete: true,
      },
    },
    component: () => import("@/views/student-grades-head-lecturer/Show.vue"),
  },
  {
    path: "/student-transcript",
    name: "student-transcript",
    meta: {
      pageTitle: "Transkrip Nilai",
      permission: {
        name: [
          "print/student-transcript",
        ],
      },
      action: "read",
      actions: {
        show: true,
        edit: true,
        delete: false,
      },
    },
    component: () => import(`@/views/student-transcript/Index.vue`),
  },
];
