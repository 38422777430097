export default [
  {
    path: "/study-plan",
    name: "study-plan",
    meta: {
      pageTitle: "Kartu Rencana Studi",
      permission: {
        name: ["read/study-plan", "show/study-plan", "import/study-plan"],
      },
      action: "read",
      actions: {
        show: true,
        edit: true,
      },
    },
    component: () => import("@/views/study-plan/Index.vue"),
  },
  {
    path: "/study-plan/:id/edit",
    name: "study-plan",
    meta: {
      pageTitle: "Update Kartu Rencana Studi",
      breadcrumb: [
        {
          text: "Kartu Rencana Studi",
          to: "/study-plan",
          active: false,
        },
      ],
      action: "edit"
    },
    component: () => import("@/views/study-plan/Form.vue"),
  },
  {
    path: "/study-plan/:course/show/:year/:semester",
    name: "study-plan",
    meta: {
      pageTitle: "Detail Kartu Rencana Studi",
      breadcrumb: [
        {
          text: "Kartu Rencana Studi",
          to: "/study-plan",
          active: false,
        },
      ],
      permission: {
        name: ["edit/study-plan", "destroy/study-plan", "import/study-plan"],
      },
      action: "show",
      actions: {
        import: true,
        delete: true,
      },
    },
    component: () => import("@/views/study-plan/Show.vue"),
  },
];
