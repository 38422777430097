import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import { ValidationObserver } from 'vee-validate';
import VueAxios from "vue-axios";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import axios from "./libs/axios";
import validate from "./libs/validate";
import Dayjs from 'vue-dayjs';

// 3rd party plugins
import '@/libs/sweet-alerts'

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// custome
Vue.use(VueAxios, axios);

// component custome
Vue.component('ValidationProvider', validate)
Vue.component('ValidationObserver', ValidationObserver);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

Vue.use(Dayjs);


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
