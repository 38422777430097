export default [
  {
    path: "/feedback-lecturer",
    name: "feedback-lecturer",
    meta: {
      pageTitle: "Kuesioner",
    //   permission: {
    //     name: ["read/feedback-lecturer", "show/feedback-lecturer"],
    //   },
      action: "read",
      actions: {},
    },
    component: () => import(`@/views/feedback-lecturer/Index.vue`),
  },
  {
    path: "/feedback-lecturer/create",
    name: "feedback-lecturer",
    meta: {
      pageTitle: "Kuesioner",
      breadcrumb: [
        {
          text: "Kuesioner",
          to: "/feedback-lecturer",
          active: false,
        },
      ],
      action: "store",
    },
    component: () => import(`@/views/feedback-lecturer/Form.vue`),
  },
  {
    path: "/feedback-lecturer/:id/show",
    name: "feedback-lecturer",
    meta: {
      pageTitle: "Kuesioner",
      breadcrumb: [
        {
          text: "Kuesioner",
          to: "/feedback-lecturer",
          active: false,
        },
      ],
      action: "store",
    },
    component: () => import(`@/views/feedback-lecturer/Show.vue`),
  },
];
