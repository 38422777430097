export default [
    {
        path: "/dashboard/block",
        name: "dashboard-feedback-block",
        meta: {
            pageTitle: "Dashboard Dosen",
            permission: {
                name: [
                    "read/dashboard-feedback-block",
                    "show/dashboard-feedback-block",
                ],
            },
            action: "show",
            actions: {
                import: false,
                delete: false,
            },
        },
        component: () => import("@/views/dashboard-block/Index.vue"),
    },
    {
        path: "/dashboard/block/:lecturerId/:blockId",
        name: "dashboard-feedback-block",
        meta: {
            pageTitle: "Detail Kuesioner",
            breadcrumb: [
                {
                    text: "Dashboard Dosen",
                    to: "/dashboard/block",
                    active: false,
                },
            ],
            permission: {
                name: ["edit/dashboard-feedback-block", "import/dashboard-feedback-block"],
            },
            action: "show",
            actions: {},
        },
        component: () => import("@/views/dashboard-block/Show.vue"),
    },
    {
        path: "/dashboard/session",
        name: "dashboard-feedback-session",
        meta: {
            pageTitle: "Dashboard Dosen",
            permission: {
                name: [
                    "read/dashboard-feedback-session",
                    "show/dashboard-feedback-session",
                ],
            },
            action: "show",
            actions: {
                import: false,
                delete: false,
            },
        },
        component: () => import("@/views/dashboard-session/Index.vue"),
    },
    {
        path: "/dashboard/session/:lecturerId/:blockId",
        name: "dashboard-feedback-session",
        meta: {
            pageTitle: "Detail Kuesioner",
            breadcrumb: [
                {
                    text: "Dashboard Dosen",
                    to: "/dashboard/session",
                    active: false,
                },
            ],
            permission: {
                name: ["edit/dashboard-feedback-session", "import/dashboard-feedback-session"],
            },
            action: "show",
            actions: {},
        },
        component: () => import("@/views/dashboard-session/Show.vue"),
    },
]