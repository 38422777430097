export default [
  {
    path: "/event",
    name: "event",
    meta: {
      pageTitle: "Kalendar Akademik",
      permission: {
        name: ["create/event", "show/event", "edit/event", "read/event"],
      },
      action: "read",
      actions: {
        show: true,
        edit: true,
        delete: false,
      },
    },
    component: () => import("@/views/event/Index.vue"),
  },
  {
    path: "/event/create",
    name: "event",
    meta: {
      pageTitle: "Tambah Kalendar Akademik",
      breadcrumb: [
        {
          text: "Kalendar Akademik",
          to: "/event",
          active: false,
        },
      ],
      permission: {
        name: ["create/event"],
      },
      action: "create",
    },
    component: () => import("@/views/event/Form.vue"),
  },
  {
    path: "/event/:id/show",
    name: "event",
    meta: {
      pageTitle: "Detail Kalendar Akademik",
      breadcrumb: [
        {
          text: "Kalendar Akademik",
          to: "/event",
          active: false,
        },
      ],
      permission: {
        name: ["show/event"],
      },
      action: "show",
    },
    component: () => import("@/views/event/Form.vue"),
  },
  {
    path: "/event/:id/edit",
    name: "event",
    meta: {
      pageTitle: "Ubah Kalendar Akademik",
      breadcrumb: [
        {
          text: "Kalendar Akademik",
          to: "/event",
          active: false,
        },
      ],
      permission: {
        name: ["edit/event"],
      },
      action: "edit",
    },
    component: () => import("@/views/event/Form.vue"),
  },
];
